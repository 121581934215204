import {
    HandleCallbackCalledAction,
    HandleErrorAction,
    HandlePageChangedAction,
    HandlePageHeightAction,
    LoginUserAction,
    NavigateToAction,
    SendRegisteredCallbackAction,
    SendUnregisteredCallbackAction,
} from '../sagas/actionCreators';
import {
    GO_BACK,
    GO_FORWARD,
    HANDLE_CALLBACK_CALLED_MESSAGE,
    HANDLE_ERROR_MESSAGE,
    HANDLE_IFRAME_JS_LOADED_MESSAGE,
    HANDLE_PAGE_CHANGED_MESSAGE,
    HANDLE_PAGE_HEIGHT_MESSAGE,
    HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE,
    HANDLE_USER_LOGGED_IN_MESSAGE,
    HANDLE_USER_LOGGED_OUT_MESSAGE,
    LOGIN_USER,
    LOGOUT_USER,
    NAVIGATE_TO,
    SEND_DISPLAY_OPTIONS,
    SEND_GET_SIZE_REQUEST,
    SEND_OVERRIDDEN_ACTIONS,
    SEND_OVERRIDE_STYLE,
    SEND_REGISTERED_CALLBACK,
    SEND_SEED_DATA,
    SEND_UNREGISTERED_CALLBACK,
    SEND_UPDATE_PAGE,
    goBackThunk,
    goForwardThunk,
    loginThunk,
    logoutThunk,
    navigateToThunk,
    onCallbackCalled,
    onError,
    onIFrameJSHasLoaded,
    onPageChanged,
    onPageHeight,
    onStyleOverriden,
    onUserLoggedIn,
    onUserLoggedOut,
    sendDisplayOptions,
    sendGetPageSizeRequest,
    sendOverriddenActions,
    sendOverrideStyle,
    sendRegisteredCallback,
    sendSeedData,
    sendUnregisteredCallback,
    sendUpdatePage,
} from '../thunks';

export const combinedMiddleware = store => next => action => {
    const { dispatch, getState } = store;

    // Vérification et exécution des actions asynchrones avant tout
    if (typeof action === 'function') {
        return action(dispatch, getState);
    }

    // Exécution des autres middlewares en unifiant la logique
    switch (action.type) {
        case HANDLE_USER_LOGGED_OUT_MESSAGE:
            onUserLoggedOut()(dispatch);
            break;
        case HANDLE_USER_LOGGED_IN_MESSAGE:
            onUserLoggedIn()(dispatch);
            break;
        case HANDLE_CALLBACK_CALLED_MESSAGE:
            onCallbackCalled(action as HandleCallbackCalledAction)(dispatch, getState);
            break;
        case HANDLE_IFRAME_JS_LOADED_MESSAGE:
            onIFrameJSHasLoaded()(dispatch);
            break;
        case HANDLE_PAGE_HEIGHT_MESSAGE:
            onPageHeight(action as HandlePageHeightAction)(dispatch);
            break;
        case HANDLE_STYLE_IS_OVERRIDDEN_MESSAGE:
            onStyleOverriden();
            break;
        case HANDLE_PAGE_CHANGED_MESSAGE:
            onPageChanged(action as HandlePageChangedAction)(dispatch, getState);
            break;
        case HANDLE_ERROR_MESSAGE:
            onError(action as HandleErrorAction)(dispatch, getState);
            break;

        // sendMessageMiddleware cases
        case SEND_DISPLAY_OPTIONS:
            sendDisplayOptions()(dispatch, getState);
            break;
        case SEND_OVERRIDDEN_ACTIONS:
            sendOverriddenActions()(dispatch, getState);
            break;
        case SEND_UPDATE_PAGE:
            sendUpdatePage(action)(dispatch);
            break;
        case SEND_SEED_DATA:
            sendSeedData()(dispatch, getState);
            break;
        case SEND_GET_SIZE_REQUEST:
            sendGetPageSizeRequest()(dispatch);
            break;
        case SEND_OVERRIDE_STYLE:
            sendOverrideStyle()(dispatch);
            break;
        case SEND_REGISTERED_CALLBACK:
            sendRegisteredCallback(action as SendRegisteredCallbackAction)(dispatch);
            break;
        case SEND_UNREGISTERED_CALLBACK:
            sendUnregisteredCallback(action as SendUnregisteredCallbackAction)(dispatch);
            break;

        // navigateMiddleware cases
        case LOGOUT_USER:
            logoutThunk()(dispatch);
            break;
        case LOGIN_USER: {
            const loginAction = action as LoginUserAction;
            loginThunk(loginAction.tokenOrApiKey)(dispatch, getState);
            break;
        }
        case GO_BACK:
            goBackThunk()(dispatch, getState);
            break;
        case GO_FORWARD:
            goForwardThunk()(dispatch, getState);
            break;
        case NAVIGATE_TO: {
            const { route, additionalParams, seedData } = action as NavigateToAction;
            navigateToThunk(route, additionalParams, seedData)(dispatch, getState);
            break;
        }

        default:
            break;
    }

    // Passe l'action au prochain middleware ou au reducer
    return next(action);
};
