import { getQueryStringFromObject } from '@partoohub/utils';

import { BUSINESS_ID_PARAM } from 'app/common/data/routeIds';
import { BASE_URL } from 'app/config';
import { LoginQueryKeys } from 'app/pages/Login/utils/enums';
import {
    ANALYTICS_REVIEW_PATH,
    ANALYTICS_VISIBILITY_PATH,
    CONNECTION_PATH,
    CONVERSATIONS_MESSAGING_PATH,
    CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
    LOG_OUT_PATH,
    POSTS_PATH,
    SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME,
    SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME,
    SETTINGS_MESSAGES_WHATSAPP_PATHNAME,
    SETTINGS_PLATFORMS_CONNECTION_APPLE_PATHNAME,
    SETTINGS_PLATFORMS_CONNECTION_GOOGLE_FACEBOOK_PATHNAME,
    SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME,
    SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME,
    VISIBILITY_BULK_UPDATE_PATH,
    VISIBILITY_DIFFUSION_PATH,
    VISIBILITY_LOCATION_ADD_PATH,
    VISIBILITY_LOCATION_PATH,
} from 'app/routing/routeIds';

import { RoutingException } from '../exceptions';

export const LOGOUT_ROUTE = 'logout';
const PRESENCE_MANAGEMENT_ROUTE = 'presenceManagement';
const GOOGLE_POSTS_ROUTE = 'googlePosts';
const POSTS_ROUTE = 'posts';
const ANALYTICS_ROUTE = 'analytics';
const BUSINESS_EDIT_ROUTE = 'business';
const BUSINESS_LIST_ROUTE = 'businesses';
const BUSINESS_CREATE_ROUTE = 'add';
const PARTNER_CONNECTIONS_ROUTE = 'partnerConnections';
const REVIEW_MANAGEMENT_ROUTE = 'reviewManagement';
const REVIEW_ANALYTICS_ROUTE = 'reviewAnalytics';
const REVIEW_BOOSTER_ROUTE = 'reviewBooster';
const MESSAGING_ROUTE = 'messaging';
const BULK_UPDATE_ROUTE = 'bulkUpdate';
const REVIEW_REPLY_TEMPLATE_ROUTE = 'reviewReplyTemplate';
const MESSAGING_REPLY_TEMPLATE_ROUTE = 'messagingReplyTemplate';
const SETTINGS_CONVERSATION_STARTERS_ROUTE = 'conversationStarters';
const SETTINGS_GOOGLE_VERIFICATION_ROUTE = 'googleVerification';
const SETTINGS_APPLE_SHARED_ACCESS_ROUTE = 'appleSharedAccess';
const SETTINGS_WHATSAPP_ROUTE = 'whatsappSettings';

const FRONTEND_ROUTES: Record<string, string> = {
    [LOGOUT_ROUTE]: LOG_OUT_PATH,
    [PRESENCE_MANAGEMENT_ROUTE]: VISIBILITY_DIFFUSION_PATH,
    [GOOGLE_POSTS_ROUTE]: POSTS_PATH, // Cannot remove because used by clients in iframe
    [POSTS_ROUTE]: POSTS_PATH,
    [ANALYTICS_ROUTE]: ANALYTICS_VISIBILITY_PATH,
    [BUSINESS_EDIT_ROUTE]: VISIBILITY_LOCATION_PATH, // Cannot remove because used by clients in iframe
    [BUSINESS_LIST_ROUTE]: VISIBILITY_LOCATION_PATH,
    [BUSINESS_CREATE_ROUTE]: VISIBILITY_LOCATION_ADD_PATH,
    [PARTNER_CONNECTIONS_ROUTE]: SETTINGS_PLATFORMS_CONNECTION_GOOGLE_FACEBOOK_PATHNAME,
    [REVIEW_MANAGEMENT_ROUTE]: CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    [REVIEW_ANALYTICS_ROUTE]: ANALYTICS_REVIEW_PATH,
    [REVIEW_BOOSTER_ROUTE]: CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
    [MESSAGING_ROUTE]: CONVERSATIONS_MESSAGING_PATH,
    [BULK_UPDATE_ROUTE]: VISIBILITY_BULK_UPDATE_PATH,
    [REVIEW_REPLY_TEMPLATE_ROUTE]: SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME,
    [MESSAGING_REPLY_TEMPLATE_ROUTE]: SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME,
    [SETTINGS_CONVERSATION_STARTERS_ROUTE]: SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME,
    [SETTINGS_GOOGLE_VERIFICATION_ROUTE]: SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME,
    [SETTINGS_APPLE_SHARED_ACCESS_ROUTE]: SETTINGS_PLATFORMS_CONNECTION_APPLE_PATHNAME,
    [SETTINGS_WHATSAPP_ROUTE]: SETTINGS_MESSAGES_WHATSAPP_PATHNAME,
};

type ReviewStatus = 'live' | 'treated' | 'not-treated' | 'deleted';

type PostStatus = 'all' | 'published' | 'unpublished' | 'pending';

export type AdditionalParams = {
    businessId?: string;
    status?: ReviewStatus;
};

interface NavigationQueryParameters {
    [BUSINESS_ID_PARAM]?: string;
    status?: ReviewStatus | PostStatus;
}

class NavigationService {
    static generateURL(
        route: string,
        additionalParams: AdditionalParams = {},
        selectedBusinessId: string | null = null,
    ): string {
        if (!FRONTEND_ROUTES[route]) {
            throw new RoutingException(`⛔️ Route "${route}" not available`);
        }

        if (route == GOOGLE_POSTS_ROUTE) {
            console.warn(
                '⚠️ Warning : The route "googlePosts" is deprecated and has been replaced with "posts".',
            );
        }

        let fullPath = `${BASE_URL}${FRONTEND_ROUTES[route]}`;
        const queryParameters: NavigationQueryParameters = {};

        if (route === BUSINESS_EDIT_ROUTE) {
            if (additionalParams.businessId) {
                fullPath = `${fullPath}/${additionalParams.businessId}`;
            } else if (selectedBusinessId) {
                // Append to path the selectedBusinessId when on business edit.
                fullPath = `${fullPath}/${selectedBusinessId}`;
            }
        } else if (route === REVIEW_MANAGEMENT_ROUTE) {
            queryParameters.status = additionalParams.status || 'live';
        } else if ([POSTS_ROUTE, GOOGLE_POSTS_ROUTE].includes(route)) {
            queryParameters.status = additionalParams.status || 'all';
        }

        if (selectedBusinessId) {
            queryParameters[BUSINESS_ID_PARAM] = selectedBusinessId;
        }

        if (Object.keys(queryParameters).length !== 0) {
            fullPath = `${fullPath}?${getQueryStringFromObject(queryParameters)}`;
        }

        return fullPath;
    }

    static getShortUrlFromFullUrl(fullPath: string): string {
        return fullPath.split(BASE_URL)[1];
    }

    static generateLoginURL(
        apiKeyOrConnectionToken: string | undefined,
        nextRoute: string,
        selectedBusinessId: string | null = null,
    ): string {
        let connectionInformation = {};
        if (apiKeyOrConnectionToken) {
            const isConnectionToken = apiKeyOrConnectionToken.startsWith('\\x');
            connectionInformation = isConnectionToken
                ? {
                      connection_token: apiKeyOrConnectionToken,
                  }
                : {
                      api_key: apiKeyOrConnectionToken,
                  };
            if (!isConnectionToken) {
                console.warn(
                    '⚠️ Using api key to log users is deprecated. ' +
                        'This method will stop working on June 15th 2021.' +
                        'You should be using connection token instead. ' +
                        'See https://developers.partoo.co/rest_api/v2/#tag/Connection-Tokens',
                );
            }
        }

        if (!FRONTEND_ROUTES[nextRoute]) {
            throw new RoutingException(`⛔️ Route "${nextRoute}" not available`);
        }

        let nextPage = FRONTEND_ROUTES[nextRoute];
        if (selectedBusinessId && nextRoute === BUSINESS_EDIT_ROUTE) {
            // Handle selectedBusinessId as path upon login (taking parameter from init).
            nextPage = `${FRONTEND_ROUTES[nextRoute]}/${selectedBusinessId}`;
        } else if (selectedBusinessId) {
            nextPage = `${FRONTEND_ROUTES[nextRoute]}?${BUSINESS_ID_PARAM}=${selectedBusinessId}`;
        }

        const queryParams = getQueryStringFromObject({
            ...connectionInformation,
            [LoginQueryKeys.NEXT_PAGE]: nextPage,
        });

        return `${BASE_URL}${CONNECTION_PATH}?${queryParams}`;
    }
}

export default NavigationService;
